import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { recaptcha } from '../../../../mixins/recaptcha'

import FollowUpByCode from '../../../../components/FollowUpCode'

import STitle from '../../../../components/ui/s-title'
import SButton from '../../../../components/ui/s-button'
import lockerIcon from '../../../../assets/images/issues/locker_eco_icon.svg'
import issuesImg from '../../../../assets/images/issues/home.svg'

export default {
  name: 'IssuesHome',
  mixins: [recaptcha],

  components: {
    FollowUpByCode,
    STitle,
    SButton,
  },

  async mounted() {
    const { slug, slugId } = this.$router.currentRoute.params
    await this.getCompanyBySlug({ slug, slugId })

    if (!this.isIssueFeatureEnabled) {
      this.$router.push(`/company/${slug}`)
    }
  },

  data() {
    const { slug, slugId } = this.$router.currentRoute.params

    return {
      lockerIcon,
      issuesImg,
      showError: false,
      showNotice: false,
      slug,
      slugId,
    }
  },

  computed: {
    ...mapState(['error', 'notice', 'loading', 'company']),
    ...mapGetters(['isIssueFeatureEnabled']),
  },

  methods: {
    ...mapActions(['getIssueByProtocol', 'getCompanyBySlug']),
    ...mapMutations(['setError', 'setLoading']),

    async openIssueForm() {
      this.showNotice = false

      const isSuccess = await this.performRecaptcha('issues')
        .catch((error) => this.setError(error))
        .finally(() => this.setLoading(false))

      if (isSuccess) {
        this.$router.push({
          name: 'issues-form',
          params: { slug: this.slug, slugId: this.slugId, agree: true },
        })
      } else {
        this.showNotice = false
        this.setError('UNAVAILABLE')
      }
    },

    async findIssueByCode(code) {
      const success = await this.performRecaptcha('getIssueByCode').catch(
        this.setError,
      )

      if (success) {
        const issue = await this.getIssueByProtocol({ protocol: code })

        this.$router.push({
          name: 'issue-chat',
          params: { code, issueId: issue.id },
        })
      } else {
        this.setError('UNAVAILABLE')
      }
    },
  },

  watch: {
    notice() {
      this.showNotice = true
    },

    error() {
      if (this.error == 'NOT_FOUND' && !this.companyId) {
        this.$router.push('/404')
      } else {
        this.showError = !!this.error
      }
    },
  },
}
