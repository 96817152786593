import STitle from '../../components/ui/s-title'
import SButton from '../../components/ui/s-button'

export default {
  name: 'IssuesFollowUpCode',
  props: ['loading'],

  components: {
    STitle,
    SButton,
  },

  data() {
    return {
      showInput: false,
      protocol: '',
    }
  },

  methods: {
    findIssue() {
      this.$emit('enter:code', this.protocol.trim())
    },
  },
}
